import React from "react";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";
import { Container } from "../../components/global";
import Header from "../../components/sections/terms-privacy/header";
import Footer from "../../components/sections/footer";
import { P, H1, UL, Content, Section } from "../../styles/TCandPrivacyStyles";

const Security = () => (
  <Layout>
    <SEO title="OPay Security" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <P fontSize={["md", null, "lg"]}>
          Welcome to the OPay Security section of our website, where you can learn more about our approach in security
          and compliance for your OPay account.
        </P>
      </Content>

      <Content>
        <Section>
          <H1>WHY</H1>
          <P>
            Cybersecurity risks are real. You know about them as well as we do. We are aware how important it is to stay
            secure, especially when your finances are involved. We provide the tools which make your life easier but, at
            the same time, we want you to feel safe while using them. Your safety is important to us.
          </P>
        </Section>
        <Section>
          <H1>HOW</H1>
          <P>
            We believe in the <span>10th Immutable Law of Security: “Technology is not a panacea.”</span> That is the
            reason why we are still improving our procedures, performing threat modeling, security reviews, and testing
            our product against any possible threats.
          </P>
          <P>
            Our main activities are to continuously analyze risks, which could affect our users and to mitigate them
            before something wrong could happen. We use risk assessment methodology, which is consistent with PCI-DSS
            standards.
          </P>
          <P>
            Moreover, we are aware that threats are not only purely technological but attackers can try social
            engineering techniques by attacking our employees. For this reason, we built the Continuous Awareness
            Training Programme, which helps our personnel gain and test their knowledge against possible security
            threats like phishing or stealing passwords.
          </P>
        </Section>
        <Section>
          <H1>WHAT</H1>
          <P>
            On a regular basis, we manage and take care of all the details to give you the best and most secure version
            of OPay by:
          </P>
          <P>
            <UL>
              <li>Building a security culture across our company</li>
              <li>Performing risk assessments</li>
              <li>Maintaining procedures with due care</li>
              <li>Reviewing and improving our processes and much more</li>
            </UL>
          </P>
          <P>
            Our efforts allowed us get a <span>PCI-DSS Compliance Level 1 Certification</span>. It is the ultimate proof
            that our product is being built and maintained in alignment with all security standards.
          </P>
          <H1>WHO</H1>
          <P>
            We create and improve our security for you! Our users’ security is our number 1 priority. That's why we
            built the OPay Security Team, whose focus is to check our systems daily, take care of our security
            environment and investigate any possible issues which may cause potential problems now or in the future.
          </P>
          <P>
            We are aware that security is a continuous process and there's always something more we can do. In this
            case, this <span>“something”</span> is your contribution! Please help us build a better and more secure OPay
            by participating in our OPay Bug Bounty Programme. It is also a great opportunity to see your name in our
            Hall of Fame.
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default Security;

const pageData = {
  headerData: {
    captionText: "OPay Security",
    H1Text:
      "Welcome to the OPay Security section of our website, where you can learn more about our approach in security and compliance for your OPay account.",
  },
};
